import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../../../../commons/components/runtime-context/connect'
import {getTicketById} from '../../../../selectors/tickets'
import {isShowAccessibilityMode} from '../../../../selectors/seating'
import {Ticket as Presentation} from './ticket'
import {TicketOwnProps, TicketRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps, {place}: TicketOwnProps): TicketRuntimeProps => {
  return {
    showAccessibilityMode: isShowAccessibilityMode(state),
    ticket: getTicketById(state.tickets, place?.ticketId),
  }
}

export const Ticket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
