import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {getSelectedTicketsWithPlaceInfo, getSelectedPlace, isShowAccessibilityMode} from '../../../../selectors/seating'
import {MobileOwnProps, MobileRuntimeProps} from './interfaces'
import {Mobile as Presentation} from './mobile'

const mapRuntime = ({state, actions: {setShowAccessibilityMode}}: DetailsPageAppProps): MobileRuntimeProps => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)

  return {
    showAccessibilityMode: isShowAccessibilityMode(state),
    setShowAccessibilityMode,
    selectedPlace: getSelectedPlace(state),
    placesInBasketCount: placesInBasket.length,
    placesInBasket,
  }
}

export const Mobile = connect<MobileOwnProps, MobileRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
